
import { defineComponent, nextTick } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import BookingDetails from '../components/BookingDetails.vue'

interface Voucher {
  players: number;
  code: string;
}

interface VoucherData {
  middlesbrough: Voucher[];
  hexham: Voucher[];
  location: string;
}

export default defineComponent({
  name: 'Vouchers',
  data(): VoucherData {
    return {
      middlesbrough: [
        { players: 2, code: 'UFVQ2PJS4ACWJ'}, { players: 3, code: 'YSCPPUCSGD3H8'}, 
        { players: 4, code: 'W3EZG4VN3VDNU'}, { players: 5, code: 'HT32ZQQ7MHAPS'}, 
        { players: 6, code: 'D72JPKBLSR4XU'}
      ],
      hexham: [
        { players: 2, code: 'Y5S2XJVUMBT3Q'}, { players: 3, code: 'BBTGN6TLB7R5S'}, 
        { players: 4, code: '6T2LX93JLCLVQ'}, { players: 5, code: 'SFJ8797BT7W8L'}, 
        { players: 6, code: 'H9RGUMD9PZCPU'}
      ],
      location: window.location.pathname.split("/")[1]
    }
  },
  components: {
    PageHeader,
    BookingDetails
  },
  mounted() {
    const w = window as any;
    w.fbq('track', 'ViewContent');
  }
});
